export default {
    locale_title: "简",
    locale_code: "zh_cn",
    placeholder_input: "请输入",
    placeholder_select: "请选择",
    error_code: {
        "100": "验证码发送成功，请查看简讯",
        "901": "手机号码已被其他用户绑定",
        "902": "验证码发送失败，请稍后再试",
        "903": "验证失败 code=903",
        "904": "验证码过期",
        "905": "验证码错误",
    },

    // 选项
    Tabbar: {
        Scan: "扫描",
        History: "量测历史",
        Setting: "设定",
        Personal: "个人资料",
    },

    // 按鈕
    Button: {
        Login: "登入",
        Logout: "登出",
        Register: "注册",
        Save: "保存",
        Confirm: "确认",
        Cancel: "取消"
    },

    // 登入页
    Login: {
        username: "帐号",
        password: "密码",
        Tip: {
            forgot: "忘记密码",
            no_account: "没有帐号",
            register: "立即注册"
        },
        Notify: {
            login: "登入失败",
        }
    },

    // 注册页
    Register: {
        username: "帐号",
        password: "密码",
        confirm_password: "确认密码",
        country_code: "国码",
        mobile: "手机",
        code: "验证码",
        name: "姓名",
        sex: "性別",
        birthday: "生日",
        height: "身高",
        weight: "体重",
        has_child: "是否生育过",
        Tip: {
            return: "返回",
            mobile: "验证手机",
            content: "寄发验证码",
            countdown: "重新发送"
        },
        Notify: {
            country_code: "请先选择国码",
            mobile: "请先输入完整手机号码",
            sendCodeToNewUser: "寄发验证码失败",
            validateNewUser: "验证失败",
            register: "注册失败",
        }
    },

    // 基本资料页
    Personal: {
        country_code: "国码",
        mobile: "手机",
        code: "验证码",
        name: "姓名",
        sex: "性別",
        birthday: "生日",
        height: "身高",
        weight: "体重",
        has_child: "是否生育过",
        Tip: {
            basic: "您的基本资料",
            mobile: "验证手机",
            content: "寄发验证码",
            countdown: "重新发送"
        },
        Notify: {
            country_code: "请先选择国码",
            mobile: "请先输入完整手机号码",
            sendCodeToNewUser: "寄发验证码失败",
            validateNewUser: "验证失败",
            save_success: "保存成功",
            save_error: "保存失败",
        }
    },

    // 扫描页
    Scan: {
        start_measure: "开始测量",
        car_scan: "小车扫描",
        hand_scan: "手持扫描",
    },

    // 量测历史页
    History: {
        search: "输入搜寻条件",
        history_records: "历史测量记录",
        measure_records: "量测记录",
        note: "备注",
        chest: "胸围",
        waistline: "腰围",
        hip: "臀围",
        Notify: {
            save_success: "保存成功",
            save_error: "保存失败",
        }
    },

    // 设定頁
    Setting: {
        setting: "设定",
        select_language: "选取语言",
        using_teaching: "使用教学",
        disclaimers: "免责说明",
        about_us: "关于我们",
        parameter_settings: "参数设定",
        feedback: "意见反馈",
        delete: "删除账户",
        you_confirm_delete: "您确定要删除吗？",
        delete_success: "删除成功",
        delete_fail: "删除失败",
        language: {
            zh_hk: "繁体中文",
            zh_cn: "简体中文",
            en_us: "英语"
        }
    },
    Feedback: {
        feedback: "意见反馈",

        please_choose_fd_category: "请选择反馈类别",
        feedback_categories: {
            option1: "功能建议",
            option2: "功能缺陷",
            option3: "遇见错误"
        },
        please_description_question: "请描述问题",
        we_will_reply_you: "我们会在收到反馈的第一时间回复您",
        please_leave_your_contact_method: "请留下连络方式",
        please_input_your_phone_or_email: "请输入手机号码或邮箱",
        confirm: "确定提交",
        contact: "连络方式",
        fd_category: "反馈类别",
        question: "问题",
        success: "反馈成功",
        please_choose: "请选择"
    },


    ForgetPwd: {
        verify_mobile_number: "验证手机号码",
        agreement: {
            title: "注册即代表阅读并同意",
            service: "服务协议",
            and: "和",
            privacy: "隐私指引",
        },
        input_mobile_verify_code: "请输入手机验证码",
        already: {
            send: "已向",
            verify_code: "发送验证码",
        },
        resend: "重新发送",
        reset_pwd: "重设密码",
        password_reset_success: "密码重设成功",
        input_password: "请输入密码",
        re_input_password: "请再次输入密码",
        password: {
            required1: "密码由8-16位数字、字母或符号组成",//密碼由8-16位數字、字母或符號所組成
            required2: "至少包含2种以上字符"
        }

    },
    Privacy: {
        title: "衣朵y云3D 隐私权限政策"
    }
};
