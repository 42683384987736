const AuthRoutes = {
    path: '/auth',
    meta: {
        requiresAuth: false
    },
    children: [
        {
            name: 'Login',
            path: '/login',
            component: () => import('@/views/Login.vue')
        },
        {
            name: 'Register',
            path: '/register',
            component: () => import('@/views/Register.vue')
        },
		{
		    name: 'Support',
		    path: '/support',
		    component: () => import('@/views/Support.vue')
		},
        {
            name: 'Privacy',
            path: '/privacy',
            component: () => import('@/views/Privacy.vue')
        },
        {
            name: 'ForgetPwd',
            path: '/forgetPwd',
            component: () => import('@/views/ForgetPwd/layout.vue'),
            redirect: '/forgetPwd/step1',
            children: [
                {
                    name: 'forgetPwdStep1',
                    path: 'step1',
                    component: () => import('@/views/ForgetPwd/SendVerifyCode.vue')
                },
                {
                    name: 'forgetPwdStep2',
                    path: 'step2',
                    component: () => import('@/views/ForgetPwd/InputVerifyCode.vue')
                },
                {
                    name: 'forgetPwdStep3',
                    path: 'step3',
                    component: () => import('@/views/ForgetPwd/ResetPwd.vue')
                }
            ]
        },
        // {
        //     name: 'Error',
        //     path: '/auth/404',
        //     component: () => import('@/views/Error.vue')
        // },
    ]
};

export default AuthRoutes;
