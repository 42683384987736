import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        // {
        //     path: '/:pathMatch(.*)*',
        //     component: () => import('@/views/Error.vue')
        // },
		AuthRoutes,
        MainRoutes
    ]
});

export default router;

router.beforeEach(async (to, from, next) => {
	const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
	const token = localStorage.getItem('token');

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (authRequired && !token) {
			store.commit('user/SET_RETURNURL', to.fullPath);
            return next('/login');
        } else next();
    } else {
        next();
    }
});

