import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vant from 'vant'
import 'vant/lib/index.css';

import {i18n, vantLocales} from './lang'
// 对vant组件进行初始化语言设置
vantLocales(i18n.locale)

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vant);
app.use(i18n);
app.mount('#app');
