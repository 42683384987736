export default {
    locale_title: "繁",
    locale_code: "zh_hk",
    placeholder_input: "請輸入",
    placeholder_select: "請選擇",
    response_code: {
        "100": "驗證碼發送成功，請查看簡訊",
        "901": "手機號碼已被其他用戶綁定",
        "902": "驗證碼發送失敗，請稍後再試",
        "903": "驗證失敗 code=903",
        "904": "驗證碼過期",
        "905": "驗證碼錯誤",
    },
    error_code: {
        "100": "驗證碼發送成功，請查看簡訊",
        "901": "手機號碼已被其他用戶綁定",
        "902": "驗證碼發送失敗，請稍後再試",
        "903": "驗證失敗 code=903",
        "904": "驗證碼過期",
        "905": "驗證碼錯誤",
    },
    // 選項
    Tabbar: {
        Scan: "掃描",
        History: "量測歷史",
        Setting: "設定",
        Personal: "個人資料",
    },

    // 按鈕
    Button: {
        Login: "登入",
        Logout: "登出",
        Register: "註冊",
        Save: "保存",
        Confirm: "確認",
        Cancel: "取消"
    },

    // 登入頁
    Login: {
        username: "帳號",
        password: "密碼",
        Tip: {
            forgot: "忘記密碼",
            no_account: "沒有帳號",
            register: "立即註冊"
        },
        Notify: {
            login: "登入失敗",
        }
    },

    // 註冊頁
    Register: {
        username: "帳號",
        password: "密碼",
        confirm_password: "確認密碼",
        country_code: "國碼",
        mobile: "手機",
        code: "驗證碼",
        name: "姓名",
        sex: "性別",
        birthday: "生日",
        height: "身高",
        weight: "體重",
        has_child: "是否生育過",
        Tip: {
            return: "返回",
            mobile: "驗證手機",
            content: "寄發驗證碼",
            countdown: "重新發送"
        },
        Notify: {
            country_code: "請先選擇國碼",
            mobile: "請先輸入完整手機號碼",
            sendCodeToNewUser: "寄發驗證碼失敗",
            validateNewUser: "驗證失敗",
            register: "註冊失敗",
        }
    },

    // 基本資料頁
    Personal: {
        country_code: "國碼",
        mobile: "手機",
        code: "驗證碼",
        name: "姓名",
        sex: "性別",
        birthday: "生日",
        height: "身高",
        weight: "體重",
        has_child: "是否生育過",
        Tip: {
            basic: "您的基本資料",
            mobile: "驗證手機",
            content: "寄發驗證碼",
            countdown: "重新發送"
        },
        Notify: {
            country_code: "請先選擇國碼",
            mobile: "請先輸入完整手機號碼",
            sendCodeToNewUser: "寄發驗證碼失敗",
            validateNewUser: "驗證失敗",
            save_success: "保存成功",
            save_error: "保存失敗",
        }
    },

    // 掃描頁
    Scan: {
        start_measure: "開始測量",
        car_scan: "小車掃描",
        hand_scan: "手持掃描",
    },

    // 量測歷史頁
    History: {
        search: "輸入搜尋條件",
        history_records: "歷史測量記錄",
        measure_records: "量測記錄",
        note: "備註",
        chest: "胸圍",
        waistline: "腰圍",
        hip: "臀圍",
        Notify: {
            save_success: "保存成功",
            save_error: "保存失敗",
        }
    },

    // 設定頁
    Setting: {
        setting: "設定",
        select_language: "選取語言",
        using_teaching: "使用教學",
        disclaimers: "免責聲明",
        about_us: "關於我們",
        parameter_settings: "參數設定",
        feedback: "意見反饋",
        delete: "删除帳號",
        you_confirm_delete: "您確定要刪除嗎？",
        delete_success: "刪除成功",
        delete_fail: "刪除失敗",
        language: {
            zh_hk: "繁體中文",
            zh_cn: "簡體中文",
            en_us: "英語"
        }
    },
    Feedback: {
        feedback: "意見回饋",
        please_choose_fd_category: "請選擇問題類別",
        feedback_categories: {
            option1: "功能建議",
            option2: "功能缺陷",
            option3: "遇見錯誤"
        },
        please_description_question: "請描述問題",
        we_will_reply_you: "我們會在收到迴響的第一時間回復您",
        please_leave_your_contact_method: "請留下連絡方式",
        please_input_your_phone_or_email: "請輸入手機號碼或郵箱",
        confirm: "確定提交",
        contact: "連絡方式",
        fd_category: "問題類別",
        question: "問題",
        success: "反饋成功",
        please_choose: "請選擇"
    },
    ForgetPwd: {
        verify_mobile_number: "驗證手機號碼",
        agreement: {
            title: "註冊即代表閱讀並同意",
            service: "服務協議",
            and: "和",
            privacy: "隱私指引",
        },
        input_mobile_verify_code: "請輸入手機驗證碼",
        already: {
            send: "已向",
            verify_code: "發送驗證碼",
        },
        resend: "重新發送",
        reset_pwd: "重置密碼",
        password_reset_success: "密碼重置成功",
        input_password: "請輸入密碼",
        re_input_password: "請再次輸入密碼",
        password: {
            required1: "密碼由8-16位數字、字母或符號所組成",
            required2: "至少會有2種以上字符"
        }
    },
    Privacy: {
        title: "衣朵雲3D 隱私權政策"
    }

};
