import { getToken } from '@/utils/auth'
const state = {
	token: getToken(),
	permissions: [],
	returnUrl: '/'
}

const mutations = {
	SET_TOKEN: (state, token) => {
		state.token = token
	},
	SET_PERMISSIONS: (state, permissions) => {
		state.permissions = permissions
	},
	SET_RETURNURL: (state, returnUrl) => {
		state.returnUrl = returnUrl
	}
}

const actions = {
	token({ commit }, view) {
		commit('SET_TOKEN', view)
	},
	permissions({ commit }, view) {
		commit('SET_PERMISSIONS', view)
	},
	returnUrl({ commit }, view) {
		commit('SET_RETURNURL', view)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
