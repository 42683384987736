<template>
  <RouterView></RouterView>
</template>

<script setup>
	import { ref, onMounted } from 'vue';
	import { RouterView } from "vue-router";

	onMounted(async () => {
		if (!localStorage.getItem('lan')) {
			localStorage.setItem('lan', 1);
		}
	})
</script>

<style lang="scss">
	html {
		background: #dedee2;
	}
	body {
		height: 100vh;
	}
	#app {
		height: 100%;
	}
</style>
