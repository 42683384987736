export default {
    locale_title: "EN",
    locale_code: "en_us",
    placeholder_input: "Please input",
    placeholder_select: "Please select",
    error_code: {
        "100": "please check your sms message",
        "901": "mobile is used by other user !",
        "902": "system error, please try latter !",
        "903": "validation fail, code=903",
        "904": "validation code expired !",
        "905": "validation code error !",
    },

    // Tabbar
    Tabbar: {
        Scan: "Scan",
        History: "History",
        Setting: "Setting",
        Personal: "Personal",
    },

    // Button
    Button: {
        Login: "Login",
        Logout: "Logout",
        Register: "Register",
        Save: "Save",
        Confirm: "Confirm",
        Cancel: "Cancel"
    },

    // Login
    Login: {
        username: "username",
        password: "password",
        Tip: {
            forgot: "Forgot password",
            no_account: "no account",
            register: "Register now"
        },
        Notify: {
            login: "Login failed",
        }
    },

    // Register
    Register: {
        username: "username",
        password: "password",
        confirm_password: "Confirm Password",
        country_code: "Country code",
        mobile: "mobile",
        code: "code",
        name: "name",
        sex: "sex",
        birthday: "birthday",
        height: "height",
        weight: "weight",
        has_child: "has child",
        Tip: {
            return: "return",
            mobile: "Verify phone",
            content: "send code",
            countdown: "resend"
        },
        Notify: {
            country_code: "Please select country code",
            mobile: "Please enter the complete phone number first",
            sendCodeToNewUser: "Sending verification code failed",
            validateNewUser: "Verification failed",
            register: "Register failed",
        }
    },

    // Personal
    Personal: {
        country_code: "Country code",
        mobile: "mobile",
        code: "code",
        name: "name",
        sex: "sex",
        birthday: "birthday",
        height: "height",
        weight: "weight",
        has_child: "has child",
        Tip: {
            basic: "Your basic information",
            mobile: "Verify phone",
            content: "send code",
            countdown: "resend"
        },
        Notify: {
            country_code: "Please select country code",
            mobile: "Please enter the complete phone number first",
            sendCodeToNewUser: "Sending verification code failed",
            validateNewUser: "Verification failed",
            save_success: "Save success",
            save_error: "Save failed",
        }
    },

    // Scan
    Scan: {
        start_measure: "start measure",
        car_scan: "car scan",
        hand_scan: "hand scan",
    },

    // History
    History: {
        search: "Input search criteria",
        history_records: "Historical measurement records",
        measure_records: "Measurement records",
        note: "Note",
        chest: "Chest",
        waistline: "Waistline",
        hip: "Hip",
        Notify: {
            save_success: "Save success",
            save_error: "Save failed",
        }
    },

    // Setting
    Setting: {
        setting: "setting",
        select_language: "Select Language",
        using_teaching: "Using Teaching",
        disclaimers: "Disclaimers",
        about_us: "About Us",
        parameter_settings: "Parameter Settings",
        feedback: "Feedback",
        delete: "delete account",
        delete_success: "delete success",
        delete_fail: "delete fail",
        you_confirm_delete: "Are you sure you want to delete?",
        language: {
            zh_hk: "Traditional Chinese",
            zh_cn: "Simplified Chinese",
            en_us: "English"
        }
    },
    Feedback: {
        feedback: "Feedback",
        please_choose_fd_category: "Please select a feedback category",
        feedback_categories: {
            option1: "Functional suggestions",
            option2: "Functional defects",
            option3: "Encountered errors"
        },
        please_description_question: "Please describe the problem",
        we_will_reply_you: "will reply to you as soon as we receive feedback",
        please_leave_your_contact_method: "Please leave your contact information",
        please_input_your_phone_or_email: "Please enter your phone number or email address",
        confirm: "Confirm submission",
        contact: "contact information",
        fd_category: "feedback category",
        question: "question",
        success: "feedback success",
        please_choose: "please choose"
    },
    ForgetPwd: {
        verify_mobile_number: "verify mobile number",
        agreement: {
            title: "the registration represents the reading and agreeing ",
            service: "service agreement",
            and: "and",
            privacy: "privacy policy",
        },
        input_mobile_verify_code: "please input mobile verify code",
        already: {
            send: "already send",
            verify_code: "verify code",
        },
        resend: "re-send",
        reset_pwd: "reset password",
        password_reset_success: "password reset success",
        input_password: "please input password",
        re_input_password: "please re-input password",
        password: {
            required1: "the password  must  length 8 - 16 bits, contain number,charators,",//密碼由8-16位數字、字母或符號所組成
            required2: "and at least 2 of them",
        }
    }

};