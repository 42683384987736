const MainRoutes = {
    path: '/',
    meta: {
        requiresAuth: true
    },
    // redirect: '/',
    component: () => import('@/layouts/FullLayout.vue'),
    children: [
		{
		    name: 'Scan',
		    path: '/scan',
		    component: () => import('@/views/Scan/index.vue')
		},
		{
		    name: 'History',
		    path: '/history',
		    component: () => import('@/views/History/index.vue')
		},
		{
		    name: 'HistoryDetail',
		    path: '/historyDetail',
		    component: () => import('@/views/History/detail.vue')
		},
		{
		    name: 'Setting',
		    path: '/setting',
		    component: () => import('@/views/Setting/index.vue')
		},
		{
			name: 'Feedback',
			path: '/setting/feedback',
			component: () => import('@/views/Setting/Feedback/Feedback.vue')
		},

		{
		    name: 'UsingTeaching',
		    path: '/usingTeaching',
		    component: () => import('@/views/Setting/using_teaching.vue')
		},
		{
		    name: 'Disclaimers',
		    path: '/disclaimers',
		    component: () => import('@/views/Setting/disclaimers.vue')
		},
		{
		    name: 'AboutUs',
		    path: '/aboutUs',
		    component: () => import('@/views/Setting/about_us.vue')
		},
		{
		    name: 'Personal',
		    path: '/personal',
		    component: () => import('@/views/Personal/index.vue')
		},
    ]
};

export default MainRoutes;